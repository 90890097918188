import { css } from "styled-components";

//Fonts
export const Sansation = css`
  font-family: Sansation, sans-serif;
`;

export const PlusJakartaSans = css`
  font-family: Plus Jakarta Sans, sans-serif;
`;
